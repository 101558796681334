export default {
  // Button
  button: {
    primary: {
      base: 'text-white bg-purple-600 border border-transparent',
      active:
        'active:bg-purple-600 hover:bg-purple-700 focus:ring focus:ring-purple-300',
      disabled: 'opacity-50 cursor-not-allowed'
    },
    outline: {
      base: 'text-purple-600 border-purple-300 border focus:outline-none',
      active:
        'active:bg-transparent hover:border-purple-500 focus:border-purple-500 active:text-purple-500 focus:ring focus:ring-purple-300',
      disabled: 'opacity-50 cursor-not-allowed bg-grey-300'
    },
    link: {
      base: 'text-purple-600 focus:outline-none border border-transparent',
      active:
        'active:bg-transparent hover:bg-purple-100 focus:ring focus:ring-purple-300',
      disabled: 'opacity-50 cursor-not-allowed'
    }
  },
  // Input
  input: {
    active: 'focus:border-purple-400 focus:ring-purple-300',
    radio:
      'text-purple-600 form-radio focus:border-purple-400 focus:outline-none focus:ring focus:ring-purple-300 focus:ring-offset-0',
    checkbox:
      'text-purple-600 form-checkbox focus:border-purple-400 focus:outline-none focus:ring focus:ring-purple-300 focus:ring-offset-0 rounded'
  },
  textarea: {
    active: 'focus:border-purple-400 focus:ring-purple-300'
  },
  // Select
  select: {
    active: 'focus:border-purple-400 focus:ring-purple-300'
  },
  // Badge
  badge: {
    primary: 'text-purple-700 bg-purple-100'
  }
};
