import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Label, Input, Button } from "@windmill/react-ui";
import { authenticationActions } from "../redux/actions";
import { Trans } from 'react-i18next';
import { Link } from "react-router-dom";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    // this.props.dispatch(authenticationActions.logout());

    this.state = {
      email: "",
      password: "",
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email, password } = this.state;
    const { dispatch } = this.props;
    if (email && password) {
      dispatch(authenticationActions.login(email, password));
    }
  }

  render() {
    // const { loggingIn } = this.props;
    const { loggedIn } = this.props;
    if (loggedIn) {
      return <Redirect exact from="/login" to="/app/dashboard" />;
    }
    return (
      <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
        <div className="flex-1 h-full max-w-xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
          <div className="flex flex-col overflow-y-auto md:flex-row">
            <main className="flex items-center justify-center p-6 sm:p-12 w-full md:w-2/2">
              <div className="w-full">
                <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                  Login
                </h1>
                <Label>
                  <span><Trans i18nKey="EMAIL" /></span>
                  <Input
                    name="email"
                    className="mt-1"
                    type="email"
                    placeholder="john@doe.com"
                    onChange={this.handleChange}
                  />
                </Label>

                <Label className="mt-4">
                  <span><Trans i18nKey="PASSWORD" /></span>
                  <Input
                    name="password"
                    className="mt-1"
                    type="password"
                    placeholder="***************"
                    onChange={this.handleChange}
                  />
                </Label>

                <Button className="mt-4" block onClick={this.handleSubmit}>
                  <Trans i18nKey="LOGIN" />
                </Button>

                <p className="mt-4">
                  <Link
                    className="text font-medium text-purple-600 dark:text-purple-400 hover:text-purple-800"
                    to="/forgot-password"
                  >
                    <Trans i18nKey="FORGOT_PASSWORD_QUESTION" />
                  </Link>
                </p>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn, error } = state.authentication;
  return {
    loggedIn,
    error,
  };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage };
